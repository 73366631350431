<template>
    <Dialog v-model:visible="modelDialog" :style="{ width: '60%' }" header="About TS6300 and TiPure Formulator"
        :modal="true" class="p-fluid">
        <modelinfo />
        <template #footer>
            <Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideInfo" />
        </template>
    </Dialog>

    <Dialog v-model:visible="showTutorial" :style="{ width: '80%' }" header="Formulator Tutorial" :modal="true"
        class="p-fluid">
        <div class="card">
            <FormulatorTutorial />
        </div>
        <template #footer>
            <Button label="Close" class="p-button-text" @click="showTutorial = false" />
        </template>
    </Dialog>

    <Dialog v-model:visible="showContactForm" :style="{ width: '50%' }" header="" :modal="true" class="p-fluid">
        <Panel v-show="!messageSent">
            <div :visible="!messageSent">
                <h6>Send your questions, our technical services team will respond on your regstered email with us.</h6>
                <Textarea v-model="textdata" :autoResize="true" rows="8" cols="30" />
                <div style="display: inline">
                    <Button label="Submit" class="p-button" @click="messageSubmit"></Button>
                </div>
            </div>
        </Panel>
        <div class="contactmsginfo" v-if="contactmesg">
            <p :class="{ contactmsgsucc: contactmsgsucc }">{{ contactmesg }}</p>
        </div>
        <!-- <Panel v-show="messageSent"> -->
        <div class="card" v-show="messageSent">
            <span>
                <h6></h6>
            </span>
        </div>
        <!-- </Panel> -->
        <br />
    </Dialog>

    <div>
        <div class="portfolio">
            <div class="grid">
                <div class="col-9 p-md-9 p-lg-9 formulator-marketing shadow-3"
                    :style="{ backgroundImage: 'url(assets/layout/images/formulator_image.gif)' }">
                    <h1>
                        The Ti-Pure<sup><small>TM</small> </sup> Formulator
                    </h1>
                    <h5>Practical innovation to meet tomorrow's challenges today</h5>
                </div>
                <div class="col-3 p-md-3 p-lg-3 contact-box-container">
                    <div class="contact-box grid shadow-3">
                        <h4 class="col-12">Need Help?</h4>
                        <div class="col-12">
                            <span>Access the Ti-Pure Formulator Tutorial</span>
                        </div>
                        <div class="col-12">
                            <!--<Button label="Tutorial" @click="showTutorial = true" class="p-button-rounded p-button-raised p-button-info p-mb-2" />
                        -->
                            <a class="p-button-rounded p-button-raised p-button-info"
                                href="https://ccuse2tipurestorage.blob.core.windows.net/public/formulatortutorial.pdf"
                                target="_new">
                                <button label="Tutorial"
                                    class="p-button-rounded p-button-raised p-button-info p-mb-2">Tutorial</button>
                            </a>
                        </div>
                        <div class="col-12">
                            <span>Contact Technical Services Team</span>
                        </div>

                        <div class="col-12">
                            <Button @click="
                                showContactForm = true;
                            mesageSent = false;
                            contactmesg = null;
                            " label="Contact" class="p-button-rounded p-button-raised p-button-info p-mb-2" />
                        </div>
                    </div>
                </div>
                <div class="col-6 p-md-6 p-lg-6 formulator-desc">
                    <h5>Optimize Formulas to achieve your outcomes digitally</h5>
                    <div>
                        The Ti-pure™ Formulator leverages Machine Learning techniques and our decades of experience with
                        TiO2 for coatings to make formulation development faster or more efficient. The formulator allows
                        you to manipulate the amount
                        and type of ingredients of a paint formula to meet desired characteristics. You have the option to
                        work with our exclusive model that allows you to optimize Ti-Pure™ TS-6300 and other architectural
                        pigments under a variety of
                        compositions. You also have the option to upload your own models.*
                    </div>
                    <h5>Key features</h5>
                    <div>
                        <ul>
                            <li>Preloaded set of data showing the effect of the paint composition on multiple paint
                                properties</li>
                            <li>Explore the opportunities of the Ti-Pure™ TS-6300 pigment</li>
                            <li>Utilize the tool on your own schedule with 24/7 access</li>
                            <li>Ability to work with your own datasets and models*</li>
                        </ul>
                    </div>
                    <h6>
                        <small>* - For contracted customers only </small>
                    </h6>
                </div>
                <div class="col-3 p-md-3 p-lg-3 no-r-pad">
                    <div class="card model-info shadow-2">
                        <h6>
                            <!--Ti-Pure™ TS6300-->
                            {{ defaultvalue }} Module
                        </h6>
                        <div>
                            <!-- This model has been developed by Chemours and allows you to validate the value of Ti-Pure™ TS-6300 versus universal TiO2 pigments. It also provides a way to optimize your formulas to the desired characteristics by changing
                            the PVC, type and amount of extenders, etc.*-->
                            {{ defaultModelDescription }}
                        </div>
                        <router-link
                            :to="{ name: 'formulation', params: { module: defaultvalue, account: 'chemourscom', myportfolio: 'true' } }"
                            v-slot="{ navigate }">
                            <Button :disabled="defaultstatus" label="Launch"
                                @click="SharedState.setModel(this.defaultvalue), navigate"
                                class="p-button-rounded p-button-raised p-button-secondary p-mb-2" />
                        </router-link>
                    </div>
                </div>
                <div class="col-3 p-md-3 p-lg-3">
                    <div class="card model-info shadow-2">
                        <h6>Custom Modules</h6>
                        <div>The Ti-Pure™ Formulator allows you to upload your own models to facilitate your development
                            work. You can upload one model or many. It’s up to you.</div>
                        <!-- <router-link :to="{ name: 'formulation', params: { module: 'Polisan_dataset_Apr2021' } }" v-slot="{ navigate }">
                            <Button
                                label="Launch"
                                @click="
                                    SharedState.setModel('Polisan_dataset_Apr2021');
                                    navigate;
                                "
                                class="p-button-rounded p-button-raised p-button-secondary p-mb-2"
                            />
                        </router-link>-->
                        <Button label="Launch" @click="this.$router.push('/userportfolio')"
                            class="p-button-rounded p-button-raised p-button-secondary p-mb-2" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import modelinfo from '../components/modelinfo.vue';
import FormulatorService from '../tipureServices/FormulatorService';
import AdminService from '../tipureServices/AdminService';

import EventBus from '../event-bus';
import FormulatorTutorial from '../components/FormulatorTutorial.vue';
export default {
    components: { modelinfo, FormulatorTutorial },
    inject: ['SharedState'],
    data() {
        return {
            messageSent: false,
            textdata: null,
            contactmesg: null,
            contactmsgsucc: false,
            activeuser: null,
            showContactForm: false,
            showTutorial: false,
            background_image: '',
            modelDialog: false,
            events: null,
            tasksCheckbox: [],
            products: null,
            defaultvalue: 'TS6300',
            defaultModelDescription:
                'This model has been developed by Chemours and allows you to validate the value of Ti-Pure™ TS-6300 versus universal TiO2 pigments. It also provides a way to optimize your formulas to the desired characteristics by changing the PVC, type and amount of extenders, etc.',
            defaultstatus: false,
            selectedProducts: null,
            menuItems: [
                {
                    label: 'Save',
                    icon: 'pi pi-fw pi-check',
                },
                {
                    label: 'Update',
                    icon: 'pi pi-fw pi-refresh',
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                },
            ],
        };
    },

    formulatorService: null,
    adminService: null,
    created() {
        this.formulatorService = new FormulatorService();
        this.adminService = new AdminService();
        this.defaultstatus = true;

        this.formulatorService
            .getDefaultModel()
            .then((data) => {
                this.defaultvalue = data.data.Name;
                this.defaultModelDescription = data.data.Description;
            })
            .catch((err) => {
                console.log(JSON.stringify(err));
            });

        /* this.formulatorService
            .getCombinedPortfolio()
            .then((data) => {
                //let modelList = data.data.Models;
                //this.defaultvalue = this.getDefaultmodel(modelList);
                this.defaultstatus = false;
            })
            .catch((err) => {
                this.$toast.add({ severity: 'error', summary: 'Setting default', detail: 'Error occurred while setting default model', life: 2000 });
                this.defaultstatus = false;
                console.log(err);
            });*/
    },
    mounted() {
        /*  this.formulatorService.getPortfolioDetail().then((data) => {
            EventBus.emit('portfolio-detail-loaded', data);
        });
    */
        this.formulatorService
            .getUserPortfolio()
            .then((data) => {

                EventBus.emit('portfolio-detail-loaded', data.message);
            })
            .catch((ex) => {
                console.log('ERROR loading portfolio');
                console.log(ex);
            });
        this.adminService.getAccess().then((data) => {
            if (!data['Error']) EventBus.emit('admin-access-acquired', data);
        });
        this.activeuser = this.$auth.getLoggedInUserId();
    },
    methods: {
        getDefaultmodel() {
            /*    let model_def = null;
            let def = null;
            for (let i in data) {
                if (data[i].Account === 'chemourscom') model_def = data[i].Models;
            }
            for (let i in model_def) {
                if (model_def[i].Default === true) def = model_def[i].Name;
            }
            if (def === null) {
                def = 'TS6300';
            }*/
            return 'TS458';
        },
        messageSubmit() {
            this.contactmsgsucc = false;
            this.contactmesg = 'submitting your question, please wait...';
            let tmp_obj = {};
            let tmp_dat = new Date(); //Fetch Time in UTC standard //
            tmp_obj.logdate = tmp_dat.toISOString().split('.')[0];
            console.log();
            tmp_obj.username = this.activeuser;
            tmp_obj.value = this.textdata;
            let tmp_name = this.activeuser.replace(/[@._#$%&]/g, '');
            tmp_obj.logname = tmp_name.concat('_', String(tmp_obj.logdate.replace(/[-:]/g, '')));
            this.formulatorService
                .SubmitQuery(tmp_obj, tmp_obj.username)
                .then((data) => {
                    // console.log(data);
                    if (data.status_code === 201) {
                        this.contactmsgsucc = true;
                        this.textdata = '';
                        this.contactmesg = 'Your message has been sent. Our technical services team will respond shortly.';
                        this.showTutorial = false;
                        this.messageSent = false;
                    } else {
                        this.showTutorial = false;
                        this.messageSent = false;
                        this.contactmesg = 'Something went wrong while submitting query, please try again!';
                    }
                })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'INTERNAL SERVER ERROR', detail: err, life: 5000 });
                    this.messageSent = false;
                    this.contactmesg = 'Something went wrong while submitting query, please try again!';
                    this.showTutorial = false;
                });
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        showInfo() {
            this.modelDialog = true;
        },
        hideInfo() {
            this.modelDialog = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.no-r-pad {
    padding-right: 0rem;
}

.model-info {
    border-color: #0093bc;
    border-width: 1px;
    border-style: solid;
    min-height: 16rem;
    position: relative;

    div {
        min-height: 10rem;
    }

    button {
        display: block;
        margin: 0 auto;

        margin-top: 1rem;
        padding: 0.2rem 2rem 0.2rem 2rem;
    }

    a {
        bottom: 0.8rem;
    }

    h6 {
        color: #0093bc;
        font-weight: 600;
    }
}

div {
    text-align: justify;
    text-justify: inter-word;
}

.contact-box-container {
    padding-bottom: 0rem !important;
    padding-top: 0rem;

    h2,
    h3,
    h4 {
        display: block;
    }

    .contact-box {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: #0093bc;
        height: 20rem;
        color: #fff;
        display: flex;
        justify-content: center;
        margin: 0rem !important;

        h4,
        div {
            display: block;
            margin: 0 auto;
            text-align: center;
            justify-content: center;
        }

        div {
            font-size: 92%;
        }

        button {
            background-color: #efefef;
            color: #2c2c2c;
            padding: 0.2rem 2rem 0.2rem 2rem;
        }
    }
}

.portfolio {
    padding-left: 1rem;

    .formulator-desc {
        h5 {
            color: #0093bc;
            font-weight: 600;
        }
    }

    .formulator-marketing {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;

        small {
            font-size: 40%;
        }
    }

    h5 {
        height: 10px;
    }

    .p-grid {
        .col-12 {
            .overview-box {
                padding: 0px;

                .p-button-info {
                    float: right;
                }

                .overview-box-value {
                    background: #0093bc;
                    color: #fff;
                    font-size: 14px;
                    padding: 0.3rem 0.2rem;

                    border-radius: 5px 5px 0px 0px;
                }

                .overview-box-title {
                    padding: 0.8rem 0.8rem;
                    font-size: 98%;
                    border-radius: 0px 0px 5px 5px;
                    background: #eeeeee;
                    font-weight: 300;
                }
            }
        }
    }
}

.contactmsginfo {
    color: hsl(0, 0%, 64%);
    font-weight: 400;
    font-style: normal;
}

.contactmsgsucc {
    color: #03aa19;
    font-weight: 400;
    font-style: normal;
}
</style>
