<template>
    <div class="card">Place hoder</div>
</template>
<script>
export default {
    components: {},
    name: 'FormulatorTutorial',
    inject: ['SharedState'],
};
</script>
<style lang="scss">
</style>